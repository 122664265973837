import jsPDF from "jspdf";

export const docOptions = {
    orientation: "portrait",
    format: 'a4',
    unit: "mm",
    autoPaging: 'text',
};

class PdfUtils {
    constructor() {
        this.doc = new jsPDF(docOptions);
    }

    getDoc() {
        return this.doc;
    }

    setFontSize(size) {
        this.doc.setFontSize(size);
        return this;
    }

    setFont(font) {
        this.doc.setFont(undefined, font);
        return this;
    }

    addImage(imgSrc, format, x, y, width, height, alias = '', compression = 'FAST') {
        const img = new Image();
        img.src = imgSrc;
        this.doc.addImage(img, format, x, y, width, height, alias, compression);
        return this;
    }

    drawText(text, x, y, options = {}) {
        const { font = 'normal', fontSize = 10 } = options;
        this.setFont(font).setFontSize(fontSize);

        const textHeight = this.doc.getTextDimensions(text).h;
        const adjustedY = y + textHeight; // Adjust Y to account for the top line
        this.doc.text(text, x, adjustedY);
        return this;
    }

    printNormalAndBold(inputValue, startX, y) {
        const textHeight = this.doc.getTextDimensions(inputValue).h;
        const adjustedY = y + textHeight; // Adjust Y to account for the top line
        const arrayOfNormalAndBoldText = inputValue.split('**');
        arrayOfNormalAndBoldText.map((text, i) => {
            this.setFont(i % 2 === 0 ? 'normal' : 'bold');
            this.doc.text(text, startX, adjustedY);
            startX = startX + this.doc.getTextWidth(text);
        });
        return this;
    }

    drawBox({
        title,
        subtitle,
        x,
        y,
        width = 59,
        height = 15,
        options = {}
    }) {
        const {
            fillColor = [255, 88, 91],
            textColor = [255, 255, 255],
            titleFontSize = 14,
            subtitleFontSize = 8,
            borderRadius = 1,
            borderMargin = 2,
        } = options;

        this.doc.setFillColor(...fillColor);
        this.doc.roundedRect(x, y, width, height, borderRadius, borderRadius, 'F');

        this.doc.setTextColor(...textColor);
        this.setFont('bold');

        if (title) {
            this.setFontSize(titleFontSize);
            this.drawText(title, x + borderMargin, y + borderMargin, { font: 'bold', fontSize: titleFontSize });
        }

        if (subtitle) {
            this.setFontSize(subtitleFontSize);
            this.drawText(subtitle, x + borderMargin, (y + height) - borderMargin - this.doc.getTextDimensions(subtitle).h, { font: 'bold', fontSize: subtitleFontSize });
        }

        this.setFont('normal').setFontSize(10).doc.setTextColor(0, 0, 0);
        return this;
    }
}

export default PdfUtils;
